<template>
  <!-- 订单的原单和改签单展示内容、逻辑一致。此部分内容独立为组件 -->
  <!-- 提示：例如改签按钮仅原单可用无需分类讨论便不需要让父组件处理 -->
  <div class="payment">
    <TrainHead :ticket="order" :stopList="stopList" />
    <van-cell title="订单号" :value="order.orderNo" class="auto-cell" />
    <van-cell title="下单时间" :value="order.createTime" />
    <van-cell title="订单状态" :value="order.bz1" :value-class="order.orderStatus!='T'&&order.orderStatus!='O'?'blue':'red'" />
    <van-cell title="订单总额" :value="'￥'+order.advancePayment" value-class="red" />
    <a :href="'tel:'+phone">
      <van-cell title="服务商电话" v-show="phone">
        <template #default>
          <van-icon name="phone" class="van-cell__right-icon icon-btn blue" />
        </template>
      </van-cell>
    </a>
    <div class="gap"></div>
    <!-- <van-cell title="座位类型" :value="order.seatClass" /> -->
    <van-cell title="火车编号" :value="order.trainNo" />
    <van-cell title="发车时间" :value="order.planBeginDate" />
    <van-cell title="终到时间" :value="order.planEndDate" />
    <van-cell v-if="order.ticketGate" class="auto-cell" title="检票信息" :value="order.ticketGate" />

    <div class="gap"></div>

    <van-panel>
      <template #header>
        <div class="panel-head">
          <span class="van-cell__title">车票信息</span>
          <span class="list-text-big panel-head-seat" v-if="order.seatClass">{{order.seatClass}}</span>
        </div>
      </template>
      <div v-for="(item,i) in showPeoList" :key="i">
        <van-cell :label="item.seatNo">
          <template #title>
            <div class="left-cont">
              <van-icon name="stop" color="#44b9fa" />
              <span class="custom-title">{{item.passengerName||item.passenger}}</span>
              <!-- <van-tag plain type="success">领队</van-tag> -->
            </div>
          </template>
          <template #right-icon>
            <van-button
              v-if="!refundarr.includes(item.ticketStatus)&&(item.changeOrderNo==''||$route.name=='paymentChange')"
              v-show="item.bz2"
              :type="item.bz2|btnType"
              size="small"
              plain
              @click="changearr.includes(item.bz2)?gochange(item):''"
            >{{item.bz2}}</van-button>
            <van-button
              v-else-if="!refundarr.includes(item.ticketStatus)"
              type="primary"
              size="small"
              @click="gochange(item)"
            >查看改签</van-button>
            <van-button
              v-if="refundarr.includes(item.ticketStatus)&&!$route.name.includes('Change')"
              type="primary"
              size="small"
              @click="change(item)"
              :disabled="!canChange"
            >改&nbsp;签</van-button>
            <van-button
              :loading="loadingArr.includes(item.certificateCode)"
              style="margin-left:10px"
              v-if="refundarr.includes(item.ticketStatus)"
              type="danger"
              size="small"
              @click="refund(item)"
            >退&nbsp;票</van-button>
          </template>
        </van-cell>
        <div v-if="0 in item.history">
          <div
            class="history"
            @click="gochange(history)"
            v-for="(history,historyi) in item.history"
            :key="historyi"
          >
            查看历史操作
            <van-icon name="arrow" class="history-icon" />
          </div>
        </div>
      </div>
    </van-panel>

    <div v-if="confirmarr.includes(order.bz1)">
      <div class="gap"></div>
      <van-button
        class="bottomBtn"
        type="primary"
        size="large"
        :disabled="cancelLoading"
        @click="$emit('confirm')"
        :loading="confirmLoading"
        loading-text="请稍候..."
      >出票</van-button>
      <div class="gap"></div>
      <van-button
        class="bottomBtn"
        type="default"
        size="large"
        :disabled="confirmLoading"
        @click="$emit('cancel')"
        :loading="cancelLoading"
        loading-text="请稍候..."
      >取消</van-button>
    </div>
    <div class="gap"></div>
  </div>
</template>

<script>
import { Dialog, Toast } from "vant";
import { DiffDate } from "@/services/date.js";
import { getStatusColor } from "@/services/train.js";
import {
  TrainOrderConfirmBtn,
  TrainTicketRefundBtn,
  TrainTicketChangeBtn,
} from "@/common/config";
import TrainHead from "./InfoHead";
import { getTrainStopStation, applyRefundOrder } from "@/api/train";
export default {
  components: { TrainHead },
  data() {
    return {
      confirmarr: TrainOrderConfirmBtn, // 允许显示出票按钮的状态数组
      refundarr: TrainTicketRefundBtn, // 允许显示退票，改签按钮的状态数组
      changearr: TrainTicketChangeBtn, // 允许显示查看改签按钮的状态数组
      stopList: [], // 经停站
      loadingArr: [], // 退票按钮转啊转
      canChange: true, // 是否可以点击改签
    };
  },
  computed: {
    // 人员列表有重复，此处去重
    showPeoList: function () {
      let result = {};
      this.peoList.forEach(async (element) => {
        if (result.hasOwnProperty(element.certificateCode)) {
          let oldItemId = parseInt(
            result[element.certificateCode].changeItemId
          ); // 比较新旧changeItemId
          let newItemId = parseInt(element.changeItemId); // 大的是最新的
          if (oldItemId > newItemId) {
            result[element.certificateCode].history.push(element);
          } else {
            element.history = [];
            element.history.push(...result[element.certificateCode].history);
            delete result[element.certificateCode].history;
            element.history.push(result[element.certificateCode]);
            result[element.certificateCode] = element;
          }
        } else {
          result[element.certificateCode] = element;
          result[element.certificateCode].history = [];
        }
      });
      return Object.getOwnPropertyNames(result).map(function (i) {
        return result[i];
      }); //对象转数组
    },
  },
  props: ["order", "peoList", "cancelLoading", "confirmLoading", "phone"],
  methods: {
    // 获取经停数据
    fetchStopList() {
      if(JSON.stringify(this.order) == "{}") return
      getTrainStopStation({
        TrainCode: this.order.trainNo,
        TrainDate: this.order.planBeginDate.split(" ")[0],
        FromStation: this.order.fromStation,
        ToStation: this.order.toStation,
        Corp: this.order.corp,
      }).then((response) => {
        let arr = [];
        response.forEach((element) => {
          arr.push(...element.data);
        });
        this.stopList = arr;
      });
    },
    // 改签
    change(data) {
      let obj = { peo: data, order: this.order };
      this.$store.dispatch("train/setTrainChange", obj);
      this.$store.dispatch("train/setOriginalOrderNo", data.orderNo);
      this.$store.dispatch(
        "train/setOriginPrice",
        parseFloat(data.ticketPrice)
      );
      this.$router.push("/buyTrainChange");
    },
    // 已改签，点击前往改签订单
    gochange(data) {
      this.$store.dispatch("history/setOrderItemChange", {
        corp: data.corp,
        orderNo: data.changeOrderNo,
        outOrderNo: data.changeOutOrderNo,
      });
      this.$router.push("/paymentChange");
    },
    // 退票,丢给父组件
    refund(data) {
      let days = DiffDate(new Date(), this.order.planBeginDate);
      let msg = "";
      if (days > 15)
        msg = "开车前15天（不含）以上退票，不收取退票费，是否继续？";
      else if (days > 2)
        msg = "开车时间前48小时以上退票按票价5%收取退票费，是否继续？";
      else if (days > 1)
        msg = "开车时间前24小时以上退票按票价10%收取退票费，是否继续？";
      else msg = "开车时间前24小时以内退票按票价20%收取退票费，是否继续？";
      this.$dialog
        .confirm({
          title: "提示",
          message: msg,
        })
        .then(() => {
          this.loadingArr.push(data.certificateCode);
          this.canChange = false;
          applyRefundOrder({
            orderNo: this.order.orderNo,
            outOrderNo: this.order.outOrderNo,
            originalOrderNo: this.order.bz3 || this.order.orderNo,
            itemId: data.itemId,
            corp: data.corp,
          })
            .then((response) => {
              // setTimeout(() => {
              this.$toast.success("申请退票成功，请稍后。");
              this.$emit("refund");
              this.loadingArr = this.loadingArr.filter((item) => {
                return item != data.certificateCode;
              });
              this.canChange = true;
              // }, 30 * 1000);
            })
            .catch(
              () =>
                (this.loadingArr = this.loadingArr.filter((item) => {
                  return item != data.certificateCode;
                }))
            );
        });
    },
  },
  filters: {
    btnType: function (data) {
      return getStatusColor(data);
    },
  },
  watch: {
    order() {
      this.fetchStopList();
    },
  },
};
</script>

<style lang="less" scoped>
.left-cont {
  display: flex;
  align-items: center;
}
.custom-title {
  padding: 0 0.3rem 0 0.2rem;
}
.history {
  background-color: #fffbe8;
  color: #ed6a0c;
  padding: 0 1rem;
  font-size: 10px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  .history-icon {
    font-size: 10px;
  }
}
</style>
